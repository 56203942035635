import React from 'react';

import {createRoot} from 'react-dom/client';
import {ApolloClient, from, HttpLink, ApolloProvider} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {BrowserRouter as Router} from 'react-router-dom';

import initPolyfills from '@renofi/utils/src/polyfills';
import {FlagProvider} from '@renofi/utils/src/feature-flags';
import {fetchWithRateLimitHandler} from '@renofi/api';
import {logGraphQLError, badQueryErrorLink} from '@renofi/utils';
import '@renofi/analytics/src/chilipiper.css';

import './index.css';

import {cache} from './api/cache';
import {initAnalytics} from './analytics';
import AppWrapper from './App/AppWrapper';

initPolyfills();
initAnalytics();

const apiUrl = process.env.REACT_APP_GRAPHQL_PROXY_URL;
const httpLink = new HttpLink({
  uri: apiUrl,
  fetch: fetchWithRateLimitHandler,
});
const authLink = setContext((_, {headers}) => {
  return {headers};
});
// Log any GraphQL errors or network error that occurred
const errorLink = onError(logGraphQLError);

const client = new ApolloClient({
  cache,
  name: process.env.REACT_APP_SERVICE_NAME,
  version: process.env.REACT_APP_COMMIT_REF,
  link: from([badQueryErrorLink, errorLink, authLink, httpLink]),
});

const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL,
  clientKey: process.env.REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 15,
  appName: `dashboard-app-${process.env.REACT_APP_ENV}`,
  environment: process.env.REACT_APP_ENV,
};

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <FlagProvider config={unleashConfig}>
        <Router>
          <AppWrapper />
        </Router>
      </FlagProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
