import {useEffect, useState} from 'react';

import {uniq} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {useFlag} from '@renofi/utils/src/feature-flags';
import {useScreenSize, navbarBreakpoint} from '@renofi/utils';
import {
  allowNavbarCallSchedule,
  isAuthenticated,
  isFakeLead,
  isIntroducedByContractor,
} from '@renofi/utils/src/lead';

import {leadVar} from '../../api/cache';

import homeIcon from './img/home.svg';
import dollarIcon from './img/dollar.svg';
import contractorIcon from './img/contractor.svg';
import findIcon from './img/find.svg';
import menuIcon from './img/menu-red.svg';
import phoneIcon from './img/phone.svg';
import calculatorIcon from './img/calculator.svg';
import ratesIcon from './img/rates.svg';
import logoutIcon from './img/logout-mobile.svg';
import loginIcon from './img/login-mobile.svg';
import designIcon from './img/design.svg';

const OPTIONS = {
  CONTACT: {
    id: 'CONTACT',
    icon: phoneIcon,
    label: 'Contact',
    priority: 1,
    event: 'Dashboard/Navbar-Schedule-Button-Clicked',
  },
  RENOFI_DESIGN: {
    id: 'RENOFI_DESIGN',
    icon: designIcon,
    label: 'Design',
    labelDesktop: '3D Design & estimates',
    priority: 2,
    path: 'design',
    event: 'Dashboard/RenoFi-Design-Clicked',
  },
  FIND_CONTRACTOR: {
    id: 'FIND_CONTRACTOR',
    icon: findIcon,
    label: 'Find',
    labelDesktop: 'Find a contractor',
    priority: 3,
    path: 'find-contractor',
    event: 'Dashboard/Finder-Contractor-Clicked',
  },
  CONTRACTORS: {
    id: 'CONTRACTORS',
    icon: contractorIcon,
    label: 'Contractors',
    labelDesktop: 'Check a contractor',
    priority: 4,
    path: 'contractor-check',
    event: 'Dashboard/Contractor-Check-Clicked',
  },
  CALCULATOR: {
    id: 'CALCULATOR',
    icon: calculatorIcon,
    label: 'Calculator',
    labelDesktop: 'Payment calculator',
    priority: 5,
    path: 'calculator',
    event: 'Dashboard/Monthly-Payment-Calculator-Clicked',
  },
  HOME_VALUE: {
    id: 'HOME_VALUE',
    icon: homeIcon,
    label: 'Your home',
    labelDesktop: 'Home value',
    priority: 6,
    path: 'home-value',
    event: 'Dashboard/Home-Value-Clicked',
  },
  RATES: {
    id: 'RATES',
    icon: ratesIcon,
    label: 'Rates',
    labelDesktop: 'Rates calculator',
    priority: 7,
    path: 'rates',
    event: 'Dashboard/Rate-Calculator-Clicked',
  },
  LOGOUT: {
    id: 'LOGOUT',
    icon: logoutIcon,
    label: 'Logout',
    labelDesktop: 'Logout',
    priority: 8,
    event: 'Dashboard/Logout-Options-Clicked',
  },
  LOGIN: {
    id: 'LOGIN',
    icon: loginIcon,
    label: 'Login',
    labelDesktop: 'Login',
    priority: 8,
    event: 'Dashboard/Login-Options-Clicked',
  },
};

const LOANS = {
  id: 'LOANS',
  icon: dollarIcon,
  label: 'Loans',
  labelDesktop: 'Loan options',
  priority: 0,
  path: 'loan-options',
  event: 'Dashboard/Renovation-Loan-Options-Clicked',
};

const CONTACT = OPTIONS.CONTACT;
const LOGOUT = OPTIONS.LOGOUT;
const LOGIN = OPTIONS.LOGIN;

const TOGGLE_MORE = {
  id: 'TOGGLE_MORE',
  icon: menuIcon,
  label: 'More',
  event: 'Dashboard/More-Options-Toggled',
};

const DEFAULT_OPTION = LOANS;

const MAX_OPTIONS = 5;

const useAvailableNavOptions = () => {
  const {isDesktop} = useScreenSize(navbarBreakpoint);
  const lead = useReactiveVar(leadVar);
  const [mainOptions, setMainOptions] = useState([]);
  const [additionalOptions, setAdditionalOptions] = useState([]);
  const [desktopOptions, setDesktopOptions] = useState([]);

  const allowSchedule = allowNavbarCallSchedule(lead);
  const isRatesCalculatorEnabled = useFlag('dashboard-rates-calculator');
  const isRenoFiDesignEnabled = useFlag('dashboard-renofi-design');
  const isDashboardSignupEnabled = useFlag('dashboard-sign-up');

  useEffect(() => {
    const options = {...OPTIONS};

    let mainOptions = [];
    let additionalOptions = [];

    if (!allowSchedule || isDesktop) {
      delete options.CONTACT;
    }

    if (!isRenoFiDesignEnabled || isDashboardSignupEnabled) {
      delete options.RENOFI_DESIGN;
    }

    if (
      (isDashboardSignupEnabled && isFakeLead(lead)) ||
      isIntroducedByContractor(lead)
    ) {
      delete options.FIND_CONTRACTOR;
    }

    if (isIntroducedByContractor(lead)) {
      delete options.CONTRACTORS;
    }

    if (isDesktop || !isAuthenticated(lead?.id)) {
      delete options.LOGOUT;
    }

    if (isDesktop || isAuthenticated(lead?.id)) {
      delete options.LOGIN;
    }

    if (!isRatesCalculatorEnabled) {
      delete options.RATES;
    }

    mainOptions = Object.values(options);

    if (mainOptions.length > MAX_OPTIONS - 1) {
      additionalOptions = mainOptions.splice(3);

      if (!isDesktop) mainOptions.push(TOGGLE_MORE);
    }

    const midIndex = Math.floor(mainOptions.length / 2);

    mainOptions.splice(midIndex, 0, DEFAULT_OPTION);

    setMainOptions(uniq(mainOptions));
    setAdditionalOptions(uniq(additionalOptions));

    const desktopOptions = [...mainOptions, ...additionalOptions].sort(
      (a, b) => a.priority - b.priority,
    );

    setDesktopOptions(uniq(desktopOptions));
  }, [
    isDesktop,
    allowSchedule,
    isRatesCalculatorEnabled,
    isRenoFiDesignEnabled,
    isDashboardSignupEnabled,
    isFakeLead(lead),
  ]);

  return {
    mainOptions,
    additionalOptions,
    desktopOptions,
    DEFAULT_OPTION,
    CONTACT,
    LOGOUT,
    LOGIN,
    TOGGLE_MORE,
    OPTIONS,
  };
};

export default useAvailableNavOptions;
